
import debounce from '../lib/debounce';

export default (opt: any = {}) => 
({
	...opt,
	snapCount: 0,
	activeSnap: 0,
	placeholderElems: [],

	init() {
		this.placeholderElems = [...this.$root.querySelectorAll('[data-placeholder]')];
		const headerElems = [...this.$root.querySelectorAll('table th')].slice(1); // remove the 1st element

		this.snapCount = this.placeholderElems.length;

		const calcSizes = () => {
			if (!this.placeholderElems.length) return;
			if (!headerElems.length) return;
			this.placeholderElems.forEach((el, idx) => {
				el.style.width = `${headerElems[idx].offsetWidth}px`;
				el.style.left = `${headerElems[idx].offsetLeft}px`;
			})

		};
		calcSizes();
		window.addEventListener('resize', debounce(calcSizes, 100));

		const placeholderDistance = new Array(this.placeholderElems.length).fill(0);

		const handleScroll = () => {
			const target = this.$refs.scrollArea as HTMLElement;
			const scrollLeft = target.scrollLeft;
			// console.log('handleScroll', scrollLeft);
			const clientWidth = target.clientWidth;
			this.placeholderElems.forEach((el, idx) => {
				const width = parseInt(el.style.width);
				const left = parseInt(el.style.left);
				let pos = (left + width) - (scrollLeft + clientWidth);
				// console.log(`pos[${idx}]`, pos);
				placeholderDistance[idx] = Math.abs(pos);
			})
			this.activeSnap = placeholderDistance.indexOf(Math.min(...placeholderDistance));
		}

		handleScroll();
		const delay = document.documentElement.classList.contains('ios') ? 150 : 50;
		this.$refs.scrollArea?.addEventListener('scroll', debounce(handleScroll, delay));
	},

	calcPlaceholders(delay: number = 100) {
		// console.log('calcPlaceholders', delay);
		this.placeholderElems = [...this.$root.querySelectorAll('[data-placeholder]')];
		const headerElems = [...this.$root.querySelectorAll('table th')].slice(1); // remove the 1st element

		const table = this.$root.querySelector('table');

		const calcSizes = () => {
			if (!this.placeholderElems.length) return;
			if (!headerElems.length) return;
			this.placeholderElems.forEach((el, idx) => {
				el.style.width = `${headerElems[idx].offsetWidth}px`;
				el.style.left = `${headerElems[idx].offsetLeft}px`;
			})

			// force calculate the table height and use it as min-height to workaround mobile safari bugs
			this.$root.style.minHeight = `${table?.offsetHeight}px`;
		};
		setTimeout(calcSizes, delay);
	},

	scrollTo(idx: number) {
		if (idx < 0 || idx >= this.placeholderElems.length) return;
		const firstElem = this.$root.querySelector('table th');
		const target = this.placeholderElems[idx];
		const left = parseInt(target.style.left);
		const x = left - firstElem?.offsetWidth;
		this.$refs.scrollArea?.scrollTo({ behavior: 'smooth', left: x, top: 0 });
	},
})