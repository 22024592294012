import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import uploaderyEventSetup from "../lib/uploadery-setup";

declare global {
	interface Window {
		_ABConfig?: any;
		Shoppad?: any;
	}
}

interface ProductionOptions {
	qty?: number;
	product: any;
	selected_variant?: any;
	price?: number;
	compare_price?: number;

	containerProduct?: any;
	upsellProduct?: any;

	bundleProductList?: any[];
	selected_bundle_variant?: any;
	bundle_price?: number;
	bundle_compare_price?: number;
	bundle_open?: boolean;
	global_discount_amount?: number;
	global_discount_type?: string;
}

export default (opt: ProductionOptions) => ({
	qty: 1,
	selected_variant: null,
	// totalPrice: 0,
	price: 0,
	compare_price: 0,
	total_price: 0,
	total_compare_price: 0,

	containerProduct: null,
	upsellProduct: null,

	selected_container_variant: null,
	selected_upsell_variant: null,

	global_discount_amount: 0,
	global_discount_type: "",

	bundleProductList: [],
	selected_bundle_product: null,
	selected_bundle_variant: null,
	bundle_price: 0,
	bundle_compare_price: 0,
	bundle_open: false,
	...opt,

	bundle_config: null,
	bundle_variant_config: null,

	showTable: false,
	showBottomBar: false,

	get discounted_bundle_price() {
		return this.global_discount_amount > 0 && this.global_discount_type ? this.getDiscountedPrice(this.bundle_compare_price || this.bundle_price) : this.bundle_price;
	},

	init() {
		if (!this.product) throw 'product error: must set "product"';
		// if (!this.selected_variant)
		// 	throw 'product error: must set "selected_variant"';

		this.$watch("selected_variant", (newVal: any) => {
			this.updateCartPrice();
		});

		this.$watch("selected_upsell_variant", (newVal: any) => {
			if (newVal) [...this.$root.querySelectorAll("input.customiser-container-option:checked")].forEach((x) => (x.checked = false));
			this.updateCartPrice();
		});

		this.$watch("selected_bundle_product", (newVal: any) => {
			this.onBundleOptionChange();
			this.updateCartPrice();
		});

		this.$watch("selected_bundle_variant", (newVal: any) => {
			// console.log("selected_bundle_variant", newVal);
			if (!newVal) {
				this.bundle_price = 0;
				this.bundle_compare_price = 0;
			}
			setTimeout(() => {
				this.updateCartPrice();
			}, 10);
		});

		this.$watch("bundle_open", (newVal: boolean) => {
			this.updateCartPrice();
		});

		this.$watch("showTable", (show: boolean) => {
			const el = this.$el.querySelector(".product-comparison-table");
			this.$nextTick(() => {
				if (show) disableBodyScroll(el);
				else enableBodyScroll(el);
			});
		});

		this.onOptionChange();
		if (this.bundleProductList?.length) {
			this.onBundleProductChange();
			this.onBundleOptionChange();
		}
		if (this.containerProduct) this.onContainerOptionChange();

		this.setupCustomImageUpload();

		setTimeout(() => {
			this.setupBottomBar();
		}, 500);
	},

	setupBottomBar() {
		// Set up intersection observer for bottom bar
		const bottomBar = document.body.querySelector("#product-bottom-bar");
		if (bottomBar) {
			const observer = new IntersectionObserver(
				(entries) => {
					this.showBottomBar = true;
					entries.forEach((entry) => {
						this.showBottomBar = this.showBottomBar && !entry.isIntersecting;
					});
				},
				{
					threshold: 0,
					rootMargin: "40% 0%",
				},
			);

			observer.observe(this.$refs.addtoCart);
			observer.observe(document.body.querySelector("footer") as HTMLElement);

			const starContainer = this.$root.querySelector(".stamped-product-reviews-badge.stamped-main-badge") as HTMLElement;
			if (starContainer) {
				const innerHTML = starContainer.innerHTML.trim();
				const bottomStarContainer = bottomBar?.querySelector(".stamped-product-reviews-badge.stamped-main-badge") as HTMLElement;
				if (bottomStarContainer) {
					if (innerHTML) {
						bottomStarContainer.innerHTML = starContainer.innerHTML;
					} else {
						const mutationObserver = new MutationObserver(() => {
							if (bottomStarContainer) {
								bottomStarContainer.innerHTML = starContainer.innerHTML;
							}
							// mutationObserver.disconnect();
						});
						mutationObserver.observe(starContainer, { childList: true });
					}
				}
			}
		}
	},

	setupCustomImageUpload() {
		const customImageFieldContainer = this.$root.querySelector(".custom-image-field-container");
		if (customImageFieldContainer) {
			const uploaderyBlock = this.$root.querySelector('.shopify-block.shopify-app-block[id*="_uploadery_app_block_"]');
			if (uploaderyBlock) {
				customImageFieldContainer.appendChild(uploaderyBlock);
				// console.log("uploadery block found.");
				uploaderyEventSetup();
			} else {
				console.warn("uploadery block not found");
			}
		} else {
			console.warn("custom image field container not found");
		}
	},

	updateCartPrice() {
		this.compare_price = this.selected_variant.compare_price || this.selected_variant.compare_at_price || this.selected_variant.price;
		this.price = this.global_discount_amount > 0 && this.global_discount_type ? this.compare_price : this.selected_variant.price;
		this.total_price = this.price;
		this.total_compare_price = this.compare_price;
		if (this.bundle_open) {
			const bundle_price = this.global_discount_amount > 0 && this.global_discount_type ? this.bundle_compare_price || this.bundle_price : this.bundle_price;
			this.total_price += bundle_price;
			this.total_compare_price += this.bundle_compare_price || bundle_price;
		}
		if (this.selected_upsell_variant) {
			this.total_price += this.selected_upsell_variant.price;
			this.total_compare_price += this.selected_upsell_variant.compare_price || this.selected_upsell_variant.compare_at_price || this.selected_upsell_variant.price;
		}
		if (this.selected_container_variant) {
			this.total_price += this.selected_container_variant.price;
			this.total_compare_price += this.selected_container_variant.compare_price || this.selected_container_variant.compare_at_price || this.selected_container_variant.price;
		}
		if (this.global_discount_amount > 0 && this.global_discount_type) {
			this.total_compare_price = this.total_price;
			if (this.global_discount_type == "p") this.total_price -= this.total_price * this.global_discount_amount;
			else if (this.global_discount_type == "fa") this.total_price -= this.global_discount_amount * (this.bundle_open ? 2 : 1);
		}
	},

	getDiscountedPrice(price: number) {
		if (this.global_discount_amount > 0 && this.global_discount_type) {
			if (this.global_discount_type == "p") return price - price * this.global_discount_amount;
			else if (this.global_discount_type == "fa") return price - this.global_discount_amount;
		}
		return price;
	},

	onOptionChange() {
		const options = [...this.$root.querySelectorAll("input.customiser-option:checked")].map((x) => x.value);
		if (options.length == 0) {
			this.selected_variant = this.product.variants[0];
			return;
		}
		const optionsStr = JSON.stringify(options);
		this.product.variants.forEach((variant) => {
			if (optionsStr == JSON.stringify(variant.options)) this.selected_variant = variant;
		});
	},

	onContainerOptionChange() {
		const options = [...this.$root.querySelectorAll("input.customiser-container-option:checked")].map((x) => x.value);
		if (options.length == 0) {
			this.selected_container_variant = this.containerProduct.variants[0];
			this.selected_upsell_variant = null;
			return;
		}
		const optionsStr = JSON.stringify(options);
		this.containerProduct.variants.forEach((variant) => {
			if (optionsStr == JSON.stringify(variant.options)) {
				this.selected_container_variant = variant;
				this.selected_upsell_variant = null;
			}
		});
	},

	onSelectUpsell() {
		this.selected_container_variant = null;
		this.selected_upsell_variant = this.upsellProduct.variants[0];
	},

	onBundleProductChange() {
		const productId = this.$root.querySelector("input.bundle-product:checked")?.value || null;
		this.selected_bundle_product = productId ? this.bundleProductList?.find((x) => x.id == productId) : null;
		this.bundle_price = 0;
		this.bundle_compare_price = 0;
		this.selected_bundle_variant = null;
		setTimeout(() => {
			[...this.$root.querySelectorAll(".bundle-option-list")].forEach((el) => {
				el.querySelector("input.bundle-option").checked = true;
			});
			this.onBundleOptionChange();
		}, 0);
	},

	onBundleOptionChange() {
		const options = [...this.$root.querySelectorAll("input.bundle-option:checked")].map((x) => x.value);
		const optionsStr = JSON.stringify(options);
		this.selected_bundle_product?.variants?.forEach((variant) => {
			if (optionsStr == JSON.stringify(variant.options)) this.selected_bundle_variant = variant;
		});
		this.bundle_config = null;
		this.bundle_variant_config = null;
		this.bundle_price = 0;
		this.bundle_compare_price = 0;
		if (this.selected_bundle_variant) {
			this.bundle_compare_price = this.selected_bundle_variant.compare_price || this.selected_bundle_variant.compare_at_price || this.selected_bundle_variant.price || 0;
			if (this.global_discount_amount > 0 && this.global_discount_type) {
				this.bundle_price = this.getDiscountedPrice(this.bundle_compare_price);
			} else {
				this.findBundleConfig(this.selected_bundle_variant.id);
				if (this.bundle_variant_config) {
					switch (this.bundle_config.discountType) {
						case "FIXED_AMOUNT":
							this.bundle_price = this.bundle_compare_price - Math.floor(this.bundle_config.discountValue * 100);
							break;
						case "PERCENTAGE":
							this.bundle_price = (this.bundle_compare_price * (100 - this.bundle_config.discountValue)) / 100;
							break;
					}
				}
			}
		}
	},

	findBundleConfig(variantId: string | number) {
		this.bundle_config =
			window._ABConfig.bundle_rules.find((x) => {
				const variants = JSON.parse(x.variants);
				this.bundle_variant_config = variants.find((v: any) => v.variantId == variantId) || null;
				if (this.bundle_variant_config) return x;
				return null;
			}) || null;
	},

	getBundleItems() {
		if (!this.bundle_open) return null;
		if (!window._ABConfig?.bundle_rules) {
			console.error("bundle error: appstle bundle rules not found");
			return null;
		}

		// find the bundle with the variant id
		this.findBundleConfig(this.selected_bundle_variant.id);
		if (!this.bundle_config) {
			console.error("bundle error: appstle bundle config not found");
			return null;
		}
		if (!this.bundle_variant_config) {
			console.error("bundle error: appstle bundle variant config not found");
			return null;
		}

		const bundleObj = {
			id: this.selected_bundle_variant.id,
			quantity: 1,
			properties: {
				"_appstle-bb-id": this.bundle_config.uniqueRef,
				"__appstle-bb-name": this.bundle_config.name,
				"_appstle-bundles-variants": this.selected_bundle_variant.id,
				_appstle_bundles_type: this.bundle_config.bundleType,
			},
		};
		// console.log('bundleObj', bundleObj);
		return bundleObj;
	},

	addToCart(e: Event, callback: (() => void) | null = null) {
		const objs: { id: string; quantity: number; selling_plan?: any; properties?: object }[] = [
			{
				id: this.selected_variant.id,
				quantity: this.qty,
				properties: {},
			},
		];
		const plan = this.$root.querySelector('select[name="selling_plan"]');
		if (plan) {
			objs[0].selling_plan = plan.value;
		}
		const bundleObj = this.getBundleItems();
		const rand = Math.floor(Math.random() * 100000) + 1;

		if (bundleObj) {
			objs[0].properties = {
				...objs[0].properties,
				"_chompers-bundle-with": bundleObj.id,
				"_chompers-bundle-rnd": rand,
			};
			bundleObj.properties["_rnd"] = rand;
			objs.push(bundleObj);
		}
		const customImg = this.$root.querySelector('input[name="properties[custom-image]"]')?.value || null;
		if (customImg) {
			// console.log("custom image", customImg);
			objs[0].properties = { ...objs[0].properties, "custom-image": customImg };
		} else {
			const customText = this.$root.querySelector('input[name="custom-text"]')?.value || null;
			if (customText) objs[0].properties = { ...objs[0].properties, "custom-text": customText };
		}
		if (this.selected_container_variant) {
			objs[0].properties = {
				...objs[0].properties,
				"_chompers-container-with": this.selected_container_variant.id,
				"_chompers-container-rnd": rand,
			};
			objs.push({ 
				id: this.selected_container_variant.id, 
				quantity: 1,
				properties: {
					"_rnd": rand,
					"_chompers-container-nightguard": objs[0].id
				}
			});

		} 
		if (this.selected_upsell_variant) objs.push({ id: this.selected_upsell_variant.id, quantity: 1 });
		this.$store.cart.add(objs).then(() => {
			this.$dispatch("open-mini-cart");
			if (callback) callback();
		});
	},
});
